@import './_variables';

button {
    background: none;
    border: 0;
    cursor: pointer;
    z-index: 20;
    display: block;
    position: relative;

   
   
  }
  
  .overlay{
    position: fixed; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff; 
    z-index: 1; 
   

    .overlay-content {
       
        background: rgb(255, 255, 255);
        // padding: 150px;
        position: relative;
        ::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
          }

        .nav-mobile{
            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;

            ul {
                display: flex;
                list-style-type: none;
                flex-direction: column;
                // text-align: center;
                align-items: center;
                justify-items: center;
    
                a {
                    text-decoration: none;
                    color: $primary-text-clr;
                    font-weight: 600;
                    font-size: 40px;
                    line-height: 40px;
                    letter-spacing: -1.2px;
                    text-align: center;
                    display: block;
                    margin-bottom: 10px;
                    padding: 20px;
    
    
                    &:hover {
                        text-decoration: none;
                        // text-underline-offset: 2px;
                    }
    
                    &::after {
                        transition: all 5s ease-Out;
                    }
                }
            }
        }
    }
   

}

  .no-menu{
    display: none;
  }

