@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');


@import './styles/variables';
@import './styles/mixing';



*,
body,
html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    scroll-behavior:smooth;

}

html,
body {
    overflow-x: hidden;
}

body {
    width: 100%;

    &.no-scroll {
        overflow-y: hidden;
    }
}

input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    // background: #FBFBFD;
}

h2 {

    font-weight: 600;
    font-size: 70px;
    line-height: 70px;
}

h3 {

    font-weight: 600;
    font-size: 55px;
    line-height: 66px;
    // letter-spacing: -1px;

    @media (max-width: $layout-breakpoint-small) {
        font-size: 32px;
        line-height: 42px;
    }
}


.description {
    @include seconday-fontfam(20px, 30px, 400);
    color: $text-secondary;

    @media (max-width: $layout-breakpoint-small) {
        font-size: 18px;
        line-height: 28x;
    }
    
}

.text-bold {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    color: $primary-text-clr
}

.text-line-through {
    font-family: 'Inter', sans-serif;
    text-decoration-line: line-through;
    color: rgb(164, 164, 164);
}

::selection {
    color: rgb(255, 255, 255);
    // background: rgb(0, 0, 0);
    background: rgb(0, 130, 236);
}

.img-figure {
    @include seconday-fontfam(14px, 22px, 400);
    font-style: italic;
    color: $text-secondary;
}

.divider-nm {
    border-bottom: 1px solid $grey-1;
    width: 100%;
    position: relative;
}

.scroll-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    will-change: transform;
  }
  