.wrapperx{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;

    .lottie{
        width: 400px;
        display: block;
    }

    
}

.as-cover{
    img{
        width: 100%;
        height: 720px;
        object-fit: cover;
    }
}

.as-background{
    background: url('./../images/banner 2k @1x.png');
    height: 720px;
    background-position: center;
}