// Breakpoints
$layout-breakpoint-large: 1920px;
$layout-breakpoint-medium: 1060px;
$layout-breakpoint-small: 768px;
$layout-breakpoint-xsmall: 480px;


// COLORS
// Background
$primary-clr: #000;
$background-clr-1: #ffffff;
$background-clr-2: #fafafa;
$bg-primary: rgb(0, 0, 0);
$bg-secondary: #FBFBFD;

// Project Colors
$project-kf-gamification: #1937CD;

//font colors
$primary-text-clr: #000;
$text-primary: #ffffff;
$text-primary-2: #000000;
$text-secondary: #555555;
$text-secondary-2: #767676;
$text-secondary-3: #878787;
$secondary-text-clr-2: #747474;

//grey
$grey-1: #f2f2f2;



//line spacing
$line-height-body-copy: 32px;
$line-height-body-copy-mobile: 28px;
$line-height-hero-title: 110px;
$line-height-hero-title-mobile: 55px;


//link
$link-hover-clr: rgba(0, 0, 0, 0.518);


//TODO: Clearn up
//xSmall Screen
@media (max-width: $layout-breakpoint-xsmall) {}

//Small Screen 
@media (max-width: $layout-breakpoint-small) {}

//Medium Screen
@media (max-width: $layout-breakpoint-medium) {}

//Large Screen
@media (max-width: $layout-breakpoint-large) {}