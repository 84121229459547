@import './_variables';
@import './_mixing';


.container-work {
    height: auto;
    padding-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FBFBFD;

    h3 {
        margin-bottom: 20px;
    }

    p {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 60px;
    }

    .cards {
        display: grid;
        grid-template-columns: 1fr 40% 1fr;
        grid-template-rows: 500px 500px;
        grid-column-gap: 5px;
        grid-row-gap: 5px;
        grid-template-areas:
            "c1 c2 c3"
            "c4 c2 c5";

        .card-info-sm {
            display: flex;
            flex-direction: column;
            align-items: center;
            // margin-top: 50px;
            padding: 50px;

            .title {
                text-transform: uppercase;
                color: white;
                @include seconday-fontfam(12px, 14px, 400);
                letter-spacing: 1px;
                // color: red;
                text-align: center;
            }

            .description {
                font-family: 'Poppins', sans-serif;
                font-size: 32px;
                font-weight: 500;
                line-height: 38px;
                text-align: center;
                margin: 15px 0;
                letter-spacing: -1.2px;
                color: white;
              

                @media (max-width: $layout-breakpoint-small){
                    max-width: 400px;
                }
                 //Tab
                @media (min-width:768px) and (max-width: 1060px){
                    max-width: 300px;
                    font-size: 32px;
                    line-height: 40px;
                }
                @media (min-width:1061px)and (max-width: 1280px) {
                    max-width: 500px;
                    font-size: 22px;
                    line-height: 30px;
                    margin: 5px 0 15px 0;
                }
               //Large Screen
               @media (min-width: $layout-breakpoint-large) {
                max-width: 400px;
                font-size: 40px;
                line-height: 48px;
            }
            }

            .link {
                color: rgb(0, 102, 255);
                @include seconday-fontfam(16px, 28px, 400);
                text-decoration: none;


                &:hover{
                    color: rgb(0, 119, 255);
                    transition: 0.5s;
                }
            }
        }

        .card-info-m {
            display: flex;
            flex-direction: column;
            align-items: center;
            // margin-top: 50px;
            padding: 120px 50px 0 50px;

            .title {
                text-transform: uppercase;
                color: white;
                @include seconday-fontfam(12px, 14px, 400);
                letter-spacing: 1px;
                text-align: center;
            }

            .description {
                font-family: 'Poppins', sans-serif;
                font-size: 48px;
                font-weight: 500;
                line-height: 54px;
                text-align: center;
                margin: 15px 0;
                color: white;
                @media (max-width: $layout-breakpoint-small){
                    max-width: 300px;
                }
                  //Tab
                  @media (min-width:768px) and (max-width: 1060px){
                    max-width: 500px;
                    font-size: 50px;
                    line-height: 58px;
                }
                @media (min-width:1061px)and (max-width: 1280px) {
                    max-width: 400px;
                    font-size: 32px;
                    line-height: 40px;
                }
                  //Large Screen
                  @media (min-width: $layout-breakpoint-large) {
                    max-width: 600px;
                    font-size: 60px;
                    line-height: 65px;
                }
            }

            .link {
                color: rgb(0, 102, 255);
                @include seconday-fontfam(16px, 28px, 400);
            }
        }

        .card-1 {
            border-radius: 0;
            grid-area: c1;
            background-color: rgb(0, 0, 0);
            background-size: cover;


            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .card-2 {
            grid-area: c2;
            background-color: rgb(0, 0, 0);

           
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;

            }
        }

        .card-3 {
            grid-area: c3;
            background-color: rgb(0, 0, 0);

          
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }

        .card-4 {
            grid-area: c4;
            background-color: rgb(0, 0, 0);

          
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;

            }

        }

        .card-5 {
            grid-area: c5;
            background-color: rgb(0, 0, 0);

         
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
}

@media (max-width:767px) {
    .container-work {
        align-items: flex-start;

        h3 {
            padding: 0 30px;
            @include seconday-fontfam(48px, 54px, 700);

        }

        .description {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 60px;
            padding: 0 30px;
        }

        .cards {
            display: flex;
            flex-direction: column;

            .card-info-m {
                padding: 50px;

                .title {
                    text-transform: uppercase;
                    color: rgb(255, 255, 255);
                    @include seconday-fontfam(14px, 14px, 500);
                    letter-spacing: 1px;
                    text-align: center;
                }

                .description {
                    font-size: 32px;
                    font-weight: 500;
                    line-height: 38px;
                    text-align: center;
                    margin: 15px 0;
                    letter-spacing: -1.2px;
                    max-width: 400px
                }

                .link {
                    color: blue;
                    @include seconday-fontfam(16px, 28px, 400);
                }
            }


            .card-1 {
                grid-area: c1;
                height: 500px;



                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            .card-2 {
                grid-area: c2;
                height: 500px;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;

                }
            }

            .card-3 {
                grid-area: c3;
                height: 500px;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            .card-4 {
                grid-area: c4;
                height: 500px;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            .card-5 {
                grid-area: c5;
                height: 500px;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

@media (min-width:768px) and (max-width: 1060px) {
    .container-work {
        padding-top: 150px;

        h3 {
            font-size: 50px;
            margin-bottom: 20px;
        }

        p {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 60px;
        }

        .cards {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 500px 500px 500px;
            grid-column-gap: 5px;
            grid-row-gap: 5px;
            grid-template-areas:
                "c1 c2 "
                "c3 c2"
                "c4  c5";
        }
    }
}

@media (min-width:1061px)and (max-width: 1280px) {
    .container-work {

        .cards {
            grid-template-rows: repeat(2, 350px);

        }
    }
}

@media (min-width:1920px) {
    .container-work {

        padding-top: 150px;

        h3 {
            font-size: 50px;
            margin-bottom: 20px;
        }

        p {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 60px;
        }

        .cards {
            max-width: 100%;
            grid-template-rows: repeat(2, 580px);

        }
    }
}