@import './variables';
@import './_mixing';


.wrapper {
    @media (max-width: $layout-breakpoint-small) {
        padding: 0 30px;
    }

    .about {
        margin-top: 150px;
        margin-bottom: 150px;
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;

        @media (max-width: $layout-breakpoint-small) {
            margin-top: 100px;
            margin-bottom: 100px;
        }

        .welcome {
            font-weight: 700;
            font-size: 150px;
            line-height: 150px;
            letter-spacing: -1.5px;
            margin-bottom: 40px;

            @media (max-width: $layout-breakpoint-small) {
                font-size: 100px;
                line-height: 100px;
                margin-bottom: 30px;
            }

        }

        .intro-description {
            @include seconday-fontfam(20px, $line-height-body-copy, 400);
            max-width: 980px;
            // margin-bottom: 30px;
            color: $text-secondary;

            @media (max-width: $layout-breakpoint-small) {
                margin-top: 100px;
                margin-bottom: 100px;
            }
        }

        .img-with-figure {
            display: flex;
            align-items: center;
            flex-direction: column;

            img {
                display: block;
                border-radius: 15px;
                margin-left: auto;
                margin-right: auto;
                margin-top: 100px;
                width: 70%;

                @media (max-width: $layout-breakpoint-small) {
                    width: 100%;
                }
            }
        }
    }

}
.why{
    @media (max-width: $layout-breakpoint-small) {
        padding: 100px 30px;
        // margin-top: 100px;
        // margin-bottom: 100px;
    }
    background-color: $bg-secondary;
    padding: 150px 0;
    display: flex;
    flex-direction: column;

    .image-980{
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 980px;
        margin-left: auto;
        margin-right: auto;

        img{
            margin:50px 0;
            width: 100%;
            border-radius: 15px;
        }  
    }
    .container-700{
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;

        h3{
            margin-bottom: 30px;
            align-self: flex-start;
        }
       
        p{
            max-width: 700px;
        }
    }
   
}
.how{
    @media (max-width: $layout-breakpoint-small) {
        padding: 100px 30px;
        // margin-top: 100px;
        // margin-bottom: 100px;
    }
    background-color: white;
    padding: 150px 0;
    display: flex;
    flex-direction: column;

    .image-980{
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 980px;
        margin-left: auto;
        margin-right: auto;

        img{
            margin:50px 0;
            width: 100%;
            border-radius: 15px;
        }  
    }
    .container-700{
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;

        h3{
            margin-bottom: 30px;
            align-self: flex-start;

            @media (max-width: $layout-breakpoint-small) {
                margin-bottom: 20px;
            }
        }
       
        p{
            max-width: 700px;
            margin-bottom: 40px;
        }
        .quote{
            margin-left: 30px;
            display: block;
            border-left: 2px solid black;
            padding: 0 0 0 20px;
            @include seconday-fontfam(24px, 38px, 300);

            @media (max-width: $layout-breakpoint-small) {
               font-size: 20px;
               line-height: 32px;
            }
            
        }
    }
   
}
.what{
    @media (max-width: $layout-breakpoint-small) {
        padding: 0px 30px;
        // margin-top: 100px;
        // margin-bottom: 100px;
    }
    background-color: white;
    // padding: 150px 0;
    display: flex;
    flex-direction: column;

    .container-700{
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;

        h3{
            margin-bottom: 30px;
            align-self: flex-start;

            @media (max-width: $layout-breakpoint-small) {
                margin-bottom: 20px;
            }
        }
       
        p{
            max-width: 700px;
            margin-bottom: 40px;
        }
    }
    .image-gallery{
        padding: 20px;
        max-width: 1440px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        margin-left: auto;
        margin-right: auto;

          //Small Screen 
          @media (max-width: 768px) {
            width: 100%;
            padding: 0px;
            grid-template-columns: repeat(2, 1fr);   

            :nth-child(2){
                margin-top: 60px !important;
            }
            :nth-child(3){
                margin-top: -60px;
            }
            :nth-child(5){
                margin-top: -60px !important;
            }
            
            :nth-child(7){
                margin-top: -60px !important;
            }
            
        }

        //Tab
        @media (min-width:768px) and (max-width: 1060px) {
          
        }

        //Desktop 1280
        @media (min-width:1061px)and (max-width: 1280px) {
           
        }

        img{
            width: 100%;
            border-radius: 15px;
            display: block;
        }

        .move-down{
            margin-top: 120px;

          //Small Screen 
          @media (max-width: 768px) {
            margin-top: 0px;
        }

         //Tab
         @media (min-width:768px) and (max-width: 1060px) {
            margin-top: 100px;
        }

        }
        .move-up{
            margin-top: -120px;

             //Small Screen 
          @media (max-width: 768px) {
            margin-top: 0px;
        }
         //Tab
         @media (min-width:768px) and (max-width: 1060px) {
            margin-top: -100px;
        }
        }
    }

}
.mini-cv{
    @media (max-width: $layout-breakpoint-small) {
        padding: 100px 30px;
    }
    background-color: rgb(255, 255, 255);
    padding: 150px 0;
    // display: flex;
    // flex-direction: column;

    .container-700{
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;

        h3{
            margin-bottom: 30px;
            align-self: flex-start;

            @media (max-width: $layout-breakpoint-small) {
                margin-bottom: 20px;
            }
        }
        
        .experince{
            .company{
                margin-top: 30px;
                margin-bottom: 30px;
                .com-name{
                    @include seconday-fontfam(18px, 32px, 600); 
                    margin-bottom: 10px
                }
                .responsible{
                    @include seconday-fontfam(16px, 28px, 400);
                    color: $text-secondary; 
                    margin-bottom: 20px
                }
                .date{
                    @include seconday-fontfam(10px, 32px, 400); 
                    text-transform: uppercase;
                    letter-spacing: 1.6px;
                    color: $text-secondary;
                }
            }
        }
    }

    
}
   
