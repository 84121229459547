//fonts

@mixin seconday-fontfam($size,$lineHeight, $weight) {
    font-size: $size;
    line-height: $lineHeight;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: $weight;
}

@mixin main-cta ($clr, $bg, $hClr,$hBg){
        color: $clr;
        text-decoration: none;
        font-weight:400;
        font-size: 16px;
        line-height: 18px;
        padding: 18px 32px;
        border-radius: 60px;
        background: $bg;
        border: 1px solid $clr;
        letter-spacing: -.2px;
        display: inline-block;

        &:hover {
            // color: rgb(122, 122, 122)
            transition: all .26s ease-Out;
            background: $hBg;
            border: 1px solid $hClr;
            color: $hClr;
            // font-weight:400;
        }    
}