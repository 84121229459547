@import './_mixing';
@import './_variables';

.wrapper {



    .container-contact {
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        //Small Screen 
        @media (min-width: $layout-breakpoint-small) {
            padding: 30px;
        }


        .hear {
            font-weight: 700;
            font-size: 85px;
            line-height: 92px;
            text-align: center;
            margin-bottom: 60px;

            //Small Screen 
            @media (max-width: $layout-breakpoint-small) {
                font-size: 55px;
                line-height: 72px;
                max-width: 400px;
            }

            //Tab
            @media (min-width:768px) and (max-width: 1060px) {
                font-size: 60px;
            }

            //Desktop 1280
            @media (min-width:1061px)and (max-width: 1280px) {
                font-size: 70px;
            }
        }

        .get-in-touch {
            margin-bottom: 60px;

            //Small Screen 
            @media (max-width: $layout-breakpoint-small) {
                margin-bottom: 40px;
            }

            p {
                @include seconday-fontfam(18px, 36px, 400);
                text-transform: uppercase;
                color: $text-secondary;
                text-align: center;
                letter-spacing: 1.6px;

                //Small Screen 
                @media (max-width: $layout-breakpoint-small) {
                    @include seconday-fontfam(14px, 26px, 400);
                }

                //Tab
                @media (min-width:768px) and (max-width: 1060px) {
                    @include seconday-fontfam(16px, 28px, 400);
                }
            }

            h3 {
                @include seconday-fontfam(32px, 60px, 400);
                text-align: center;
                margin-bottom: 100px;

                //Small Screen 
                @media (max-width: $layout-breakpoint-small) {
                    @include seconday-fontfam(20px, 32px, 400);
                }

                //Tab
                @media (min-width:768px) and (max-width: 1060px) {
                    @include seconday-fontfam(24px, 38px, 400);
                }

            }
        }

        .social-media {
            display: flex;
            align-items: center;
            flex-direction: column;

            p {
                @include seconday-fontfam(12px, 24px, 400);
                text-transform: uppercase;
                color: $text-secondary;
                margin-bottom: 10px;
                letter-spacing: 1.6px;

                //Small Screen 
                @media (max-width: $layout-breakpoint-small) {
                    @include seconday-fontfam(12px, 24px, 400);
                }
            }

            .icons {
                display: flex;
                align-items: center;

                img {
                    margin: 0 10px;
                    padding: 0 0;
                    cursor: pointer;
                    width: 32px;

                    &:hover {
                        // border-bottom: 1px solid $primary-clr;
                    }
                }
            }
        }
    }
}