@import './variables';
@import './_mixing';

.container {
  
    .hero {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 200px;

        //TODO: Clearn up
        //xSmall Screen
        @media (max-width: $layout-breakpoint-xsmall) {
           
        }
        //Small Screen 
        @media (max-width: $layout-breakpoint-small) {
          
        }
        //Medium Screen
        @media (max-width: $layout-breakpoint-medium) {
           
        }
        //Large Screen
        @media (max-width: $layout-breakpoint-large) {
           
        }
        

        .title {
            font-weight: bold;
            font-size: 90px;
            line-height: $line-height-hero-title;
            max-width: 1200px;
            text-align: center;
            margin-bottom: 30px;
            letter-spacing: -1.3px;
        }

        .body {
            @include seconday-fontfam(20px, $line-height-body-copy, 400);
            text-align: center;
            max-width: 900px;
            margin-bottom: 30px;
            color: $text-secondary;
        }

      
        .cta {
            color: $primary-text-clr;
            text-decoration: none;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            padding: 18px 32px;
            border-radius: 60px;
            background: $primary-clr;
            color: $text-primary;
            border: 1px solid black;
            letter-spacing: -.2px;

            &:hover {
                // color: rgb(122, 122, 122)
                transition: all .26s ease-Out;
                background: white;
                border: 1px solid black;
                color:black;
                // font-weight:400;
            }

    
        }

        .hero-img-desktop {
            // position: absolute;
            // bottom: 0;
            margin-top: 180px;
            max-width: 990px;
        }
        .hero-img-mobile {
           display: none;
           
        }
    }
}

.design-intro{
    padding: 150px 0  150px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .title{
        font-size: 50px;
        margin-bottom: 30px;
        text-align: center;
        max-width: 800px;
    }
    .body {
        @include seconday-fontfam(20px, 32px, 400);
        text-align: center;
        color: $text-secondary;
        max-width: 800px;
    }

}

.about-me{
    padding: 100px 60px;
    background: $bg-primary;
    display: flex;
    flex-direction: column;
    align-items: center;

    .intro{
        color: $text-primary;
        text-align: center;
        font-size: 28px;
        font-weight: 300;
        margin-bottom: 30px;
        max-width: 700px
    }
    .cta{
        @include main-cta(black, white, white, black);
    }
    // max-width: 700px;

}
//media queries
@media  (max-width:767px) {
    .container {
        padding: 0 30px;

        .hero {
            display: block;
            padding-top: 100px;

            .title {
                font-size: 60px;
                line-height: $line-height-hero-title-mobile;
                min-width: 312px;
                text-align: left;
                margin-bottom: 30px;
                text-align: left;
            }

            .body {

                font-size: 16px;
                line-height: $line-height-body-copy-mobile;
                text-align: left;
                min-width: 312px;
                margin-bottom: 30px;
            }

            .cta {
                display: inline-block;
                font-size: 16px;
                padding: 15px 30px;
                border-radius: 60px;

                &:hover {
                    color: rgb(122, 122, 122)
                }
            }

            .hero-img-desktop {
               display: none;
            }
            .hero-img-mobile {
                width: 90%;
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-top: 100px;
                
             }
        }
    }

    .design-intro{
        padding: 100px 30px;
        align-items: flex-start;

        .title{
            font-size: 32px;
            margin-bottom: 30px;
            text-align: left;
           
        }
        .body {
            @include seconday-fontfam(16px, 28px, 400);
            text-align: left;
            color: $text-secondary;
            max-width: 800px;
        }
    
    }

    .about-me{
        padding: 100px 30px;
        background: $bg-primary;
        display: flex;
        flex-direction: column;
        align-items: center;
    
        .intro{
            color: $text-primary;
            text-align: center;
            font-size: 22px;
            font-weight: 300;
            margin-bottom: 30px;
            max-width: 500px
        }
        .cta{
            @include main-cta(black, white, white, black);
        }
        // max-width: 700px;
    
    }

}


@media  (min-width:768px) and (max-width: 1060px){
    .container {
        padding: 0 60px;

        .hero {
            .title {
                font-size: 60px;
                line-height: 70px;
                min-width: none;
            }

            .body {

                font-size: 18px;
                max-width: 800px;
            }

            .cta {
                display: inline-block;
                font-size: 16px;
                padding: 15px 30px;
                border-radius: 60px;
                // min-width: 165px;

                &:hover {
                    color: rgb(122, 122, 122)
                }
            }

            .hero-img-desktop {
                width: 100%;
                margin-top: 180px;
                max-width: 700px;
                ;
            }
        }
    }

    .design-intro{
        padding: 150px 60px;
        .title{
            font-size: 36px;
            margin-bottom: 30px;
            text-align: center;
            font-weight: 700;
            line-height: 54px
        }
        .body {
            @include seconday-fontfam(16px, 28px, 400);
            text-align: center;
            color: $text-secondary;
            max-width: 800px;
        }
    
    }

}