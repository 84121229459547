@import './_mixing';
@import './_variables';

.mt-50{
    margin-top: 50px;
}

.container-t {
    max-width: 720px;

    h2 {
        font-size: 56px;
        font-weight: 600;
        margin-bottom: 30px;
        // text-align: center;

        //Small Screen 
        @media (max-width: $layout-breakpoint-small) {
            font-size: 32px;
            margin-bottom: 20px;
        }

        //Tab
        @media (min-width:768px) and (max-width: 1060px) {}

        //Desktop 1280
        @media (min-width:1061px)and (max-width: 1280px) {}
    }

    h3 {
        font-size: 37px;
    }

    p {
        @include seconday-fontfam(20px, 32px, 400);
        color: $text-secondary-2;
        // text-align: center;

        //Small Screen 
        @media (max-width: $layout-breakpoint-small) {
            font-size: 18px;
        }


    }

    .points {
        margin-top: 50px;

        //Small Screen 
        @media (max-width: $layout-breakpoint-small) {
            margin-top: 30px;
        }

        ol {
            margin-left: 30px;

            li {
                margin-bottom: 10px;
                @include seconday-fontfam(20px, 32px, 400);

                //Small Screen 
                @media (max-width: $layout-breakpoint-small) {
                    font-size: 18px;
                }
            }
        }

        p {
            @include seconday-fontfam(16px, 28px, 400);
            color: $text-secondary-2;
        }
    }
}

.img-grp-sol {
    h4 {
        @include seconday-fontfam(16px, 24px, 400);
        margin-bottom: 20px;
    }

    img {
        width: 100%;
        margin-bottom: 50px;
    }
}


.container-case-study {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    .heading {
        max-width: 980px;
        margin-top: 100px;
        margin-bottom: 100px;

        .project-name {
            margin-bottom: 10px;
            text-transform: uppercase;
            letter-spacing: 1.5px;
            font-size: 16px;
            font-weight: 500;
            color: $text-secondary-3;

            //Small Screen 
            @media (max-width: $layout-breakpoint-small) {
                font-size: 14px !important;
            }
        }

        .project-short-description {
            @include seconday-fontfam(72px, 83px, 700);
            letter-spacing: -1.2px;

            //Small Screen 
            @media (max-width: $layout-breakpoint-small) {}

            //Tab
            @media (min-width:768px) and (max-width: 1060px) {
                @include seconday-fontfam(55px, 67px, 700);
            }

            //Desktop 1280
            @media (min-width:1061px)and (max-width: 1280px) {
                @include seconday-fontfam(64px, 76px, 700);
            }
        }
    }

    .cover-container {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 150px;
        overflow: hidden;
        height: 1100px;
        object-fit: cover;

        @media (max-width:767px) {
            height: 600px;
            margin-bottom: 100px;
        }

        @media (min-width:768px) and (max-width: 1060px) {
            height: 900px;
        }

        //Desktop 1280
        @media (min-width:1061px)and (max-width: 1280px) {
            height: 1000px;
        }

        .cover-img {
            object-fit: cover;
            width: 100%;
            height: 100%;

            @media (max-width:768px) {
                max-height: 700px;
            }

            @media (min-width:769px) and (max-width: 1060px) {
                height: 1200px;
            }
        }

        .cover-obj {
            // object-fit: cover;
            width: 1200px;

            @media (min-width:375px) and (max-width: 820px) {
                // max-width: 400px;
                width: 100%;
                padding: 0 10px 0 10px;

            }

            @media (min-width:821px) and (max-width: 1060px) {
                width: 1000px;
            }
        }
    }

    .bg_tinderbox {
        background-color: #40B2F1;
    }

    .bg_kfgamification {
        background-color: #DDEEFE;
        // background-color: #FABE1E;
    }



    .container-980 {
        max-width: 980px;
        margin-bottom: 150px;

        //Small Screen 
        @media (max-width: $layout-breakpoint-small) {
            margin-bottom: 100px;

        }

        .properties {
            display: grid;
            grid-template-columns: 215px 1fr;
            margin-bottom: 30px;
            grid-column-gap: 20px;

            .property {
                // @include seconday-fontfam(20px, 32px, 600);
                font-weight: 600;
                font-size: 20px;
                line-height: 26px;
            }

            .value {
                @include seconday-fontfam(18px, 32px, 400);
                max-width: 735px;
                width: fit-content;
                color: rgb(65, 65, 65);

                .highlight-large {
                    @include seconday-fontfam(20px, 32px, 600);

                    display: block;
                    margin-bottom: 10px;
                }

                .highlight-bold {
                    @include seconday-fontfam(24px, 32px, 600);
                    letter-spacing: -.5px;

                    //Small Screen 
                    @media (max-width: $layout-breakpoint-small) {
                        font-size: 20px !important;
                    }
                }

                .little-space {
                    margin-bottom: 20px;
                    display: inline-block;

                    //Small Screen 
                    @media (max-width: $layout-breakpoint-small) {
                        margin-bottom: 10px;
                    }
                }
            }

            .col-2 {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 30px;
            }

            .image-value {
                max-width: 535px;
                // max-width: 100%;
                max-height: 100%;
            }
        }
    }

    .container-full {
        width: 100%;
        // background: #DDEEFE;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-bottom: 150px;

        //Small Screen 
        @media (max-width: $layout-breakpoint-small) {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }

        //Tab
        @media (min-width:768px) and (max-width: 1060px) {
            width: 100%;
            padding: 30px;
        }

        //Desktop 1280
        @media (min-width:1061px)and (max-width: 1280px) {
            width: 700px;
        }



    }

    .divider {
        border-bottom: 1px solid rgb(212, 212, 212);
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .image-980 {
        display: block;
        max-width: 980px;
        margin-left: auto;
        margin-right: auto;

        // border-radius: 15px; 
        //Small Screen 
        @media (max-width: $layout-breakpoint-small) {
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .image-1400 {
        display: block;
        // width: 1400px;
        margin-left: auto;
        margin-right: auto;

        //Small Screen 
        @media (max-width: $layout-breakpoint-small) {
            width: 100%;
            // padding: 30px;
        }

        //Tab
        @media (min-width:768px) and (max-width: 1060px) {
            width: 100%;
            // padding: 30px;
        }

        //Desktop 1280
        @media (min-width:1061px)and (max-width: 1280px) {
            width: 700px;
        }

        //Desktop 1368
        @media (min-width:1368px) and (max-width: 1440px) {
            width: 1200px;
        }

        //Desktop 1368
        @media (min-width:1441px) {
            width: 1400px;
        }
    }

    .image-full {
        display: block;
        width: 100%;
        object-fit: cover;
        // min-height: 1024px;

        //Small Screen 
        @media (max-width: $layout-breakpoint-small) {
            height: 100%;
        }

    }

    .image-auto-width {
        // min-width:500px;
        align-items: right;
        // width: 50%;
    }

}



@media (max-width:767px) {

    .container-case-study {
        overflow-x: hidden;

        .heading {
            margin-top: 60px;
            margin-bottom: 40px;

            padding: 0 30px;

            .project-name {
                @include seconday-fontfam(16px, 24px, 500);
                margin-bottom: 20px;
                text-transform: uppercase;
                letter-spacing: 1px;
            }

            .project-short-description {
                font-size: 40px;
                line-height: 40px;
            }
        }

        // .cover-container {
        // display: block;
        // height: 30px;
        // width: 100%;
        // background: url('/images/bg.png') no-repeat center center fixed; 
        // background-size: cover;
        // margin-bottom: 60px;


        // .cover-img {
        //     width: auto;
        //     object-fit: cover;
        //     max-height: 600px;
        //     object-position: center;
        //     will-change: transform;
        // margin-bottom: 60px;
        // }
        // }


        .container-980 {
            max-width: 100%;
            padding: 0 30px;

            .properties {
                display: block;
                margin-bottom: 40px;

                .property {
                    min-width: 100%;
                    margin-right: 20px;

                    font-weight: 600;
                    font-size: 20px;
                    line-height: 26px;
                    margin-bottom: 10px;
                }

                .value {
                    @include seconday-fontfam(16px, 24px, 400);
                    max-width: 100%;
                    width: fit-content;

                    .highlight-large {
                        @include seconday-fontfam(18px, 24px, 600);

                        display: block;
                        margin-bottom: 10px;
                    }

                    .highlight-bold {
                        @include seconday-fontfam(16px, 30px, 600);
                    }
                }

                .image-value {
                    // max-width: 535px;
                    max-width: 100%;
                    max-height: 100%;
                }

                .col-2 {
                    display: flex;
                    flex-direction: column;

                    .value {
                        max-width: 80%;
                        margin-bottom: 30px;
                    }
                }
            }


        }

        .divider {
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }
}

@media (min-width:768px) and (max-width: 1060px) {

    .heading {
        padding: 0 60px;
    }

    .container-980 {
        // max-width: 800px;
        padding: 0 60px;

        .properties {
            display: grid;
            grid-template-columns: 215px 1fr;

            .col-2 {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 30px;
            }
        }
    }
}