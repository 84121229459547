//single

.animate-template{

    .containerx{
        .top-row{
            height: 50vh;
            width: 100%;
            display: flex;
            align-items: flex-end;

            .top{
                padding-bottom: 40px;

                .details{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .location{
                        span:nth-child(2){
                            margin-left: 16px;
                        }
                    }
                    .mua{
                        text-transform: uppercase;
                    }
                }
                .model{
                    overflow: hidden;
                    .first {
                        margin-right: 72px;
                      }
                      span {
                        display: inline-block;
                        position: relative;
                        font-weight: 400;
                        font-size: 224px;
                        font-family: "Maragsa";
                        @media (max-width: 1440px) {
                          font-size: 128px;
                        }
                    }
                }
            }
        }
       .bottom-row{
        height: 50vh;
        position: relative;
        .bottom{
            height: 100%;
            width: 100%;
            .thumbnail-single{
                width: 100%;
                height: 800px;
                margin: 0 auto;
                overflow: hidden;
                position: absolute;
                left: 0;
                right: 0;
                .frame-single{
                    img{
                        position: absolute;
                        width: 100%;
                    }
                }
            }
        }
       } 
    }
    .detailed-information {
        margin-top: 200px;
        height: 600px;
        .containerx {
          .row {
            justify-content: space-between;
            align-items: flex-start;
            h2 {
              font-size: 28px;
            }
            p {
              font-family: "Helvetica Neue";
              font-size: 16;
              line-height: 28px;
              font-weight: 400;
              width: 800px;
            }
          }
        }
      }
}