@import './_variables';

nav {
    display: flex;
    justify-content: space-between;
    padding: 40px 40px 0 40px;

    @media (max-width: $layout-breakpoint-xsmall) {
        padding: 20px 20px 0 20px;
    }

    @media (max-width: $layout-breakpoint-small) {
        padding: 30px 30px 0 30px;

        .logo {
            img {
                height: 20px;
            }
        }
    }


    .nav-items {

        @media (max-width: $layout-breakpoint-small) {
            display: none;
        }

        ul {
            display: flex;
            list-style-type: none;

            a {
                text-decoration: none;
                margin-left: 30px;
                color: $primary-text-clr;
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                letter-spacing: 0.02em;


                &:hover {
                    text-decoration: underline;
                    text-underline-offset: 2px;
                }

                &::after {
                    transition: all 5s ease-Out;
                }
            }
        }

    }
    .menu{
        display: none;
       
        @media (max-width: $layout-breakpoint-small) {
            display: block;
        }
    }
}



//TODO: Clearn up
//xSmall Screen
@media (max-width: $layout-breakpoint-xsmall) {}

//Small Screen 
@media (max-width: $layout-breakpoint-small) {}

//Medium Screen
@media (max-width: $layout-breakpoint-medium) {}

//Large Screen
@media (max-width: $layout-breakpoint-large) {}